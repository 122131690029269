let menuData = [
    {
        "title": "作业箱",
        "url": "/s/report/boxes"
    },
    {
        "title": "选课",
        "url": "/s/xk"
    },
    {
        "title": "我的课表",
        "url": "/s/schedule_view"
    },
    {
        "title": "远程实验",
        "url": "/s/virtual_rooms"
    },
    {
        "title": "资源管理",
        "url": "/s/resource/manage"
    },
    {
        "title": "软件下载",
        "url": "/s/support_download"
    },

    {
        "title":"意见建议",
        "url":"https://support.qq.com/product/378305"
    }
    ];

if(!window.config.is_open_paike){
    menuData=menuData.filter(menu=>menu.title!=='选课'&&menu.title!=='远程实验'&&menu.title!=='我的课表')
}

module.exports = menuData;
