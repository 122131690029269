let menuData = [
    {
        "title": "管理员",
        "key":"admins",
        "url": "/a/admins"
    },
    {
        "title": "教师",
        "key":"teachers",
        "url": "/a/teachers"
    },
    {
        "title": "学生",
        "key":"students",
        "url": "/a/students"
    },
    {
        "title": "教学管理",
        "key":"teach_manage",
        "children": [
            {
                "title": "插件管理",
                "url": "/a/word_addin",
            },
            {
                "title": "软件下载",
                "url": "/s/support_download"
            },
            {
                "title": "文件上传",
                "url": "/a/software"
            },
            {
                "title": "名单管理",
                "url": "/a/name_list"
            },
        ]
    },
    {
        "title": "排课管理",
        "key":"paike",
        "children": [
            {
                "title": "课程",
                "url": "/a/courses",
            },
            {
                "title": "课时设置",
                "url": "/a/classtime_setting",
            },
        ]
    },

    {
        "title": "实验室管理",
        "key":"room",
        "children": [
            {
                "title": "实验室",
                "url": "/a/rooms",
            },
            {
                "title": "实验室签到记录",
                "url": "/a/room_checkin_records",
            },
            {
                "title": "实验室课表",
                "url": "/a/schedule_view",
            },
        ]
    },
    {
        "title": "系统对接",
        "key":"duijie",
        "children": [
            {
                "title": "口袋实验箱绑定管理",
                "url": "/a/bind_list"
            }
        ]
    },
    {
        "title": "系统操作日志",
        "key":"logs",
        "url": "/a/logs"
    },
    {
        "title": "意见建议",
        "key":"support",
        "url": "https://support.qq.com/product/378305"
    },
    {
        "title": "可视化",
        "key":"keshihua",
        "children": [
            {
                "title": "配置",
                "url": "/a/setting"
            },
            {
                "title": "大屏可视化",
                "url": "/visualization"
            }
        ]
    }
];
if(!window.config.is_open_paike){
    menuData=menuData.filter(menu=>menu.key!=='paike'&&menu.key!=='room')
}
if(!window.config.is_open_keshihua){
    menuData=menuData.filter(menu=>menu.key!=='keshihua')
}

module.exports = menuData;
