import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from "react-router-dom";
import loadable from "@loadable/component";
import BasicLayout from "./layout/BasicLayout";
import UserService from "./services/User";
import ReportLayout from "./layout/ReportLayout";
import Home from "./routes/Home";
import {ConfigProvider} from "antd";
// 由于 antd 组件的默认文案是因为，所以需要修改为中文
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

const ChangeTracker = withRouter(({match, location, history}) => {
    console.log(location.pathname, location.state);
    if (location.pathname !== '/login'&&
        !location.pathname.startsWith('/report/')&&
        location.pathname !== '/register'&&
        location.pathname !== '/reset'&&
        location.pathname !== '/a/input'&&
        !location.pathname.startsWith('/s/invite') &&
        (location.pathname && !location.pathname.startsWith('/wechat_login_callback'))
    ) {
        if (UserService.isExpired()) {
            console.log("登录时间过期！");
            window.location.hash = '/login';
        }
    }
    return false;
});


const Loading=()=><div>加载中...</div>
const NotMatch=()=><div>NotMatch</div>

const Login = loadable(() => import("./routes/Login"), {fallback: <Loading /> });
const Logout = loadable(() => import("./routes/Logout"), {fallback: <Loading /> });
const WeChatLoginCallback = loadable(() => import("./routes/WeChatLoginCallback"), {fallback: <Loading/>});

// const Home=()=><div>Home</div>
const ChangeInfo = loadable(() => import("./routes/ChangeInfo"), {fallback:<Loading />})
// 修改密码页面
const ChangePassword = loadable(() => import("./routes/login/ChangePassword"),{fallback: <Loading />})
// 管理员
const Teachers = loadable(() => import("./routes/admin/Teachers"), {fallback: <Loading/>});
const Students = loadable(() => import("./routes/admin/Students"), {fallback: <Loading/>});
const Admins = loadable(() => import("./routes/admin/Admins"), {fallback: <Loading />})
const NameList = loadable(() => import("./routes/admin/NameList"), {fallback: <Loading/>});
const NameListInfo = loadable(() => import("./routes/admin/NameListInfo"), {fallback: <Loading/>});
const Rooms = loadable(() => import("./routes/admin/Rooms"), {fallback: <Loading/>});
const Room = loadable(() => import("./routes/admin/Room"), {fallback: <Loading/>});
const RoomRustdesk = loadable(() => import("./routes/admin/RoomRustdesk"), {fallback: <Loading/>});
const ClassTimeSetting = loadable(() => import("./routes/admin/ClassTimeSetting"), {fallback: <Loading/>});
const AdminScheduleView = loadable(() => import("./routes/admin/ScheduleView"), {fallback: <Loading/>});
const RoomCheckinRecords = loadable(() => import("./routes/admin/RoomCheckinRecords"), {fallback: <Loading/>});
const AdminCourses = loadable(() => import("./routes/admin/Courses"),{fallback: <Loading />})
const UserEdit = loadable(() => import("./routes/admin/UserEdit"), {fallback: <Loading/>});
const AdminWordAddin = loadable(() => import("./routes/admin/WordAddin"), {fallback: <Loading/>});
// 软件上传
const Software = loadable(() => import("./routes/admin/Software"),{fallback: <Loading />})
// 绑定列表
const BindList = loadable(() => import("./routes/admin/BindList"),{fallback: <Loading />})
//操作日志列表
const Logs = loadable(() => import("./routes/admin/Logs"),{fallback: <Loading />})
// 昆明可视化
const SandBox = loadable(() => import("./routes/visualization/SandBox"),{fallback: <Loading/>})
//昆明可视化配置
const AdminViewSetting = loadable(() => import("./routes/admin/ViewSetting"),{fallback: <Loading />})


// 教师
const ReportBoxes = loadable(() => import("./routes/teacher/ReportBoxes"), {fallback: <Loading/>});
const ReportBox = loadable(() => import("./routes/teacher/ReportBox"), {fallback: <Loading/>});
const ReportBoxSetting = loadable(() => import( "./routes/teacher/ReportBoxSetting"), {fallback: <Loading/>});
const Reports = loadable(() => import( "./routes/teacher/Reports"), {fallback: <Loading/>});
const ReportCommentPdf = loadable(() => import( "./routes/teacher/ReportCommentPdf"), {fallback: <Loading/>});
const ReportCommentImage = loadable(() => import( "./routes/teacher/ReportCommentImage"), {fallback: <Loading/>});
const ReportCommentFile = loadable(() => import( "./routes/teacher/ReportCommentFile"), {fallback: <Loading/>});
const CommentLibrary = loadable(() => import( "./routes/teacher/CommentLibrary"), {fallback: <Loading/>});
const ResourceManage = loadable(() => import("./routes/student/ResourceManage"), {fallback: <Loading/>});
const ReportStatistics = loadable(() => import("./routes/teacher/ReportStatistics"),{fallback: <Loading />})
const Courses = loadable(() => import("./routes/teacher/Courses"),{fallback: <Loading />})
const Course = loadable(() => import("./routes/teacher/Course"),{fallback: <Loading />})
const CourseSchedules = loadable(() => import("./routes/teacher/CourseSchedules"), {fallback: <Loading/>});
const ScheduleRecords = loadable(() => import("./routes/teacher/ScheduleRecords"),{fallback:<Loading />})
const ScheduleRecordStudents = loadable(() => import("./routes/teacher/ScheduleRecordStudents"),{fallback:<Loading />})
const ScheduleView = loadable(() => import("./routes/teacher/ScheduleView"), {fallback: <Loading/>});

const PasswordReset = loadable(() => import("./routes/teacher/PasswordReset"), {fallback: <Loading/>});


//学生
const StudentReportBoxes = loadable(() => import("./routes/student/ReportBoxes"), {fallback: <Loading/>});
const StudentReportBox = loadable(() => import("./routes/student/ReportBox"), {fallback: <Loading/>});
const ReportCommentViewPdf = loadable(() => import("./routes/student/ReportCommentViewPdf"), {fallback: <Loading/>});
const ReportCommentViewImage = loadable(() => import("./routes/student/ReportCommentViewImage"), {fallback: <Loading/>});
const ReportEdit = loadable(() => import("./routes/student/ReportEdit"), {fallback: <Loading/>});
const ReportView = loadable(() => import( "./routes/student/ReportView"), {fallback: <Loading/>});
const InviteAddBox = loadable(() => import("./routes/student/InviteAddBox"),{fallback:<Loading />})
const SupportDownload = loadable(() => import("./routes/student/SupportDownload"),{fallback:<Loading />})
const WordAddin = loadable(() => import("./routes/student/WordAddin"),{fallback:<Loading />})
const XuanKe=loadable(() => import("./routes/student/XuanKe"),{fallback:<Loading />})
const StudentScheduleView = loadable(() => import( "./routes/student/ScheduleView"), {fallback: <Loading/>});
const StudentRooms = loadable(() => import( "./routes/student/StudentRooms"), {fallback: <Loading/>});
const StudentRustdesk = loadable(() => import( "./routes/student/StudentRustdesk"), {fallback: <Loading/>});


const TeacherReportCommentPdfWithLayout=(props)=>(
    <ReportLayout><ReportCommentPdf {...props}/></ReportLayout>);
const TeacherReportCommentImageWithLayout=(props)=>(
    <ReportLayout><ReportCommentImage {...props}/></ReportLayout>);
const TeacherReportCommentFileWithLayout=(props)=>(
    <ReportLayout><ReportCommentFile {...props}/></ReportLayout>);

export default  ()=>{
  return (
  <ConfigProvider locale={zhCN}>
    <Router>
        <ChangeTracker/>
        <Switch>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/logout" component={Logout}/>
            <Route exact path="/wechat_login_callback/:code" component={WeChatLoginCallback}/>
            {/*可视化*/}
            <Route path={'/visualization'} component={SandBox} />
            <Route path="/report/:id" component={ReportView}/>
            <Route path="/t/report/comment_pdf/:reportId/:boxId" component={TeacherReportCommentPdfWithLayout}/>
            <Route path="/t/report/comment_image/:reportId/:boxId/:orderField/:orderMethod" component={TeacherReportCommentImageWithLayout}/>
            <Route path="/t/report/comment_file/:reportId/:boxId/:orderField/:orderMethod" component={TeacherReportCommentFileWithLayout}/>
            <Route path={'/s/invite/:teacher/:boxId'} component={InviteAddBox} />
            <BasicLayout>
                {/*修改密码*/}
                <Route path={"/change_info"} component={ChangeInfo} />
                <Route path={'/change_password'} component={ChangePassword} />
                {/*管理员*/}
                <Route exact path="/a/teachers"><Teachers /></Route>
                <Route exact path="/a/students" component={Students}/>
                <Route exact path="/a/rooms" component={Rooms}/>
                <Route exact path="/a/courses" component={AdminCourses}/>
                <Route path="/a/Room/:id" component={Room}/>
                <Route path="/a/room_rustdesk/:id" component={RoomRustdesk}/>
                <Route exact path={'/a/admins'} component={Admins}/>
                <Route exact path="/a/name_list" component={NameList}/>
                <Route  path="/a/name_list_info/:id" component={NameListInfo}/>
                <Route path="/a/userEdit/:username" component={UserEdit}/>
                <Route path="/a/word_addin" component={AdminWordAddin}/>
                <Route path={'/a/software'} component={Software} />
                <Route path={'/a/bind_list'} component={BindList} />
                <Route path={'/a/logs'} component={Logs} />
                <Route path={'/a/classtime_setting'} component={ClassTimeSetting} />
                <Route path="/a/schedule_view" component={AdminScheduleView}/>
                <Route path="/a/room_checkin_records" component={RoomCheckinRecords}/>
                <Route path="/a/setting" component={AdminViewSetting}/>
                {/*教师*/}
                <Route exact path="/t/courses" component={Courses}/>
                <Route path="/t/course/:id" component={Course}/>
                <Route path="/t/course_schedules" component={CourseSchedules}/>
                <Route path="/t/schedules_record" component={ScheduleRecords}/>
                <Route path="/t/schedule_record/:id" component={ScheduleRecordStudents}/>
                <Route path="/t/schedules_view" component={ScheduleView}/>
                <Route exact path="/t/report/boxes" component={ReportBoxes}/>
                <Route path="/t/report/box/:id/:tabKey?" component={ReportBox}/>
                <Route path="/t/report/box_setting/:id" component={ReportBoxSetting}/>
                {/*<Route path="/t/report/box/:id" component={Reports}/>*/}
                <Route path="/t/comment_library" component={CommentLibrary}/>
                <Route path="/t/report/statistics" component={ReportStatistics} />
                <Route path="/t/password_reset" component={PasswordReset}/>
                {/*学生*/}
                <Route exact path="/s/report/boxes" component={StudentReportBoxes}/>
                <Route exact path="/s/report/box/:id" component={StudentReportBox}/>
                <Route  path="/s/report/comment_view_pdf/:reportId/:boxId" component={ReportCommentViewPdf}/>
                <Route  path="/s/report/comment_view_image/:reportId/:boxId" component={ReportCommentViewImage}/>
                <Route  path="/s/report/edit/:reportId/:boxId" component={ReportEdit}/>
                <Route  path="/s/resource/manage" component={ResourceManage}/>
                <Route  path="/s/support_download" component={SupportDownload}/>
                <Route  path="/s/wordaddin" component={WordAddin}/>
                <Route path="/s/xk" component={XuanKe}/>
                <Route path="/s/schedule_view" component={StudentScheduleView}/>
                <Route path="/s/virtual_rooms" component={StudentRooms}/>
                <Route path="/s/virtual_room/:id" component={StudentRustdesk}/>
                <Route exact path="/"><Home /></Route>
            </BasicLayout>
            <Route path="/*" component={NotMatch}/>
          </Switch>
    </Router>
  </ConfigProvider>
  );
}