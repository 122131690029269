let menuData = [
    {
        "title": "作业箱",
        "url": "/t/report/boxes"
    },
    {
        "title": "评语库",
        "url": "/t/comment_library"
    },
    {
        "title": "重置密码",
        "url": "/t/password_reset"
    },
    {
        "title": "排课管理",
        "children": [
            {
                "title": "课程",
                "url": "/t/courses"
            },
            {
                "title": "排课计划",
                "url": "/t/course_schedules"
            },
            {
                "title": "排课记录",
                "url": "/t/schedules_record"
            },
            {
                "title": "课表",
                "url": "/t/schedules_view"
            },

        ]
    },
    {
        "title": "资源管理",
        "url": "/s/resource/manage"
    },
    {
         "title": "报告统计",
         "url": "/t/report/statistics"
    },
    {
        "title": "软件下载",
        "url": "/s/support_download"
    },
    {
        "title": "名单管理",
        "url": "/a/name_list"
    },
    // {
    //     "title": "人员管理",
    //     "children": [
    //         {
    //             "title": "成员管理",
    //             "url": "/a/students"
    //         },

    //     ]
    // },
    {
        "title":"意见建议",
        "url":"https://support.qq.com/product/378305"
    }

];

if(!window.config.is_open_paike){
    menuData=menuData.filter(menu=>menu.title!=='排课管理')
}
module.exports = menuData;
